@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
.panel .ant-collapse-content-box {
  background-color: #eeeeee;
}
.ant-modal-footer {
  display: none;
}

#root {
  /* background-color: #d1d1d1; */
  font-family: Montserrat, Arial;
  font-weight: 400;

  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  display: block;
  padding: 0;
}

.footerLinks {
  font-size: 13px;
  font-weight: 400;
  width: min-content;
}

#footer-row {
  position: relative;
  white-space: nowrap;
  text-align: center;
  margin: auto;
  padding: 15px 0 10px 0;
  width: 100%;
}
.footer-row-builder {
  position: absolute !important;
  bottom: 0;
}
/* @media only screen and (max-width: 1200px) {
  #root {
    display: none;
  }
  #noMobile {
    display: block;
    text-align: center;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 50px;
    height: max-content;
  }
} */

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.dHQcjo {
  left: 10px;
}

.aoTUt {
  right: 10px;
}

.ant-btn {
  font-family: Arial, sans-serif, Arial;
}

.fileManagerItemFilename {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 1300px) {
  .ant-col-xl-offset-3 {
    margin-left: 0.1%;
  }
}

@media (min-width: 1370px) {
  .ant-col-xl-offset-3 {
    margin-left: 1.25%;
  }
}

@media (min-width: 1500px) {
  .ant-col-xl-offset-3 {
    margin-left: 6.25%;
  }
}

@media (min-width: 1600px) {
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
}

.loadingPage {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  font-size: 2em;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.loadingPage > div {
  color: #00c2df;
  font-weight: 600;
  margin-top: -2em;
}
.loadingPage div img {
  max-height: 250px;
}
.loadingPage div p {
  margin-top: 2em;
}
.loginLogo {
  margin-bottom: 2.5em;
}
.loginLogo img {
}

#ud .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#ud .trigger:hover {
  color: #1890ff;
}

#ud .logo {
}
#ud .logo img {
}

#topBarLogoAdmin {
  background-color: white;
  margin: auto;
  display: inline;
  width: 282px;
  height: 62px;
}

#topBarLogoAdmin img {
  max-height: 62px;
  float: left;
  cursor: pointer;
}
.site-layout .site-layout-background {
  background: #fff;
}

.ud-bottom-menu {
  position: absolute;
  bottom: 15vh;
}
.ud-create-signage-button {
  color: #fff !important;
  margin-bottom: 5vh !important;
  background-color: #e25929;
  text-transform: uppercase;
  font-weight: 700;
}

.ant-menu {
  font-size: 16px !important;
}

.bold {
  font-weight: 700;
}

.topNavRight {
  float: right;
  margin-right: 16px;
}
.tnr-item svg {
  vertical-align: middle;
  color: '#2f3e92';
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-btn {
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

.ant-btn-primary {
  background-color: #003764 !important;
  border-color: #003764 !important;
}

.ant-layout-sider {
  background-color: #003764 !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #003764 !important;
}

.bp3-tree-node-label {
  overflow: hidden;
  text-overflow: unset;
}
.bp3-tree-node-list {
  z-index: 10;
}
.bp3-tree-node {
  z-index: 11;
  width: 100%;
}
.bp3-tree-node-content {
  width: 100%;
  cursor: pointer;
}
.bp3-tree {
  width: 100%;
  overflow: auto;
  min-height: 150px;
  max-height: 455px;
}
.bp3-icon {
  vertical-align: text-top;
}

:focus {
  outline: none !important;
}
.bp3-button {
  border-radius: 0 !important;
}

.desktopIcons {
}
.desktopIconsIcon {
  padding-top: 2px;
  margin: 0 auto;
  height: 48px;
}
.desktopIconsLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 89px;
  display: block;
  font-size: 13px;
  padding: 2px 0 0 0;
  text-align: center;
  margin-left: -2px;
  height: 35px;
  line-height: normal;
}

.desktopIconsLabelText {
}
.desktopIconsSpan {
}
.desktopIconsRow {
}

.desktopIconsCol {
  /*padding: 5px;*/
  border: #fff 1px solid;
  float: left;
  height: 90px;
  margin: 8px 4px;
  overflow: hidden;
  padding: 2px;
  width: 90px;
  z-index: 10;
}

.desktopIconsCol.active {
  background-color: #003764;
  border-color: #f4f4f4;
  background: #d1ebff;
}
.desktopIconsColDiv:hover {
  background-color: rgba(191, 204, 214, 0.4);
}
.desktopIconsColDiv {
  display: table-cell;
  height: 90px;
  position: relative;
  vertical-align: top;
  text-align: center;
  width: 90px;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.filesUL {
  display: block;
  height: 100%;
  margin: 0;
  overflow: auto;
  padding: 0 4px;
  z-index: 1;
  list-style: none;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.thumbnail {
  max-width: 65px;
  padding: 1px;
  border: 1px solid #ccc;
  margin-top: -1px;
  margin-bottom: 1px;
}
.iconColumn {
  padding: 0 0 0 10px !important;
  margin-right: -6px;
}
.ant-table {
}
.ant-table-placeholder > .ant-table-cell {
  border-bottom: 0;
  height: 300px;
}

.ant-table-body {
  overflow: auto !important;
}

.topLinkList {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 5px;
  margin-left: 16px;
}

.topLinkList li {
  padding: 0;
  margin: 0;
  height: 17px;
  line-height: 0;
}
.topLinkList li a {
  font-size: 14px;
  color: #0a3c61;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #00c2df;
  background: #fff;
  border-color: #00c2df;
}

.largerButtonsForModal {
  padding: 10px 30px !important;
  font-size: 24px !important;
}

.linkLike {
  text-decoration: underline;
  cursor: pointer;
}
